<template>
  <div class="home-hero-bg v1">
    <div class="home-hero">

      <section class="home-hero-aside description">
          <img class="hero-image s-hide-pc-l" :src="heroImg" alt="Hero Image">
          <img class="hero-image s-show-pc-l" :src="heroImgMobile" align="middle" alt="Hero Image">

          <div class="home-hero-subtitle">
            <h1 class="home-hero-paragraph">Paste a link from another website, then <span class="s-text-red">Play</span> and <span class="s-text-blue">Download</span> it to any device.
              <br> No software required!</h1>
          </div>
        <s-button class="button-primary-blue continue s-button-round s-show-pc-xs" btn-link="/signup">{{$t('actions.start-now')}}</s-button>

      </section>
      <section class="home-hero-aside signup-comp s-hide-pc-xs">
        <SignUpForm/>
      </section>
<!--      <div class="home-hero-title">-->
<!--        <img src="/assets/images/Hero_Logo_SVG.svg" align="middle" alt="">-->
<!--        <h1>{{ $t('home-page.hero-title') }}</h1>-->
<!--      </div>-->



    </div>
  </div>
</template>

<script>
import SButton from "../components/SButton.vue";
import SignUpForm from "../components/SignUpForm.vue";

import heroImg from '/assets/images/home_hero_v1.svg?url'
import heroImgMobile from '/assets/images/home_hero_v1-mobile.png?url'

export default {
  name: 'HomeHero',
  components: {
    SignUpForm,
    SButton,
  },
  props: {
    msg: String
  },
  data() {
    return {
      heroImg: heroImg,
      heroImgMobile: heroImgMobile
    }
  }
}
</script>
