<template>
  <div class="page-parent  home-page home-v1" :class="[{'has-banner' : bannerVisible}]">
      <SBanner v-if="bannerVisible"/>
    <section class="home-section-1">
      <HomeHeroV1/>

      <HomeFeature
        class="v1 v2-platform"
        isFlipped

        title='<span class="s-text-premium">Seedr</span> Is Growing'
        subtitle='Join us and be part of the revolution'
        :main-image-source="v2GrowingImg"
        button-text="Join Premium"
        button-url="https://www.seedr.cc/premium"
        button-color="premium"
      />

      <HomeFeature
        class="v1 stream"
        id="home-products"
        title='<span class="s-text-red">Play</span>  on any device, anytime, anywhere!'
        text="Watch, Listen, Read. Anything is accessible! Whenever you get stuff with Seedr you can open and play it online on your personal Desktop, Mobile Device and even on your TV! <br><br> <small>Chromecast supported out-of-the-box</small> "
        :main-image-source="homeStreamImg"
        button-text="Read More"
        button-url="/products/streaming"
        button-color="blue-darker"
      />

      <HomeFeature
        class="v1"
        isFlipped
        title='<span class="s-text-blue-dark">Optimized</span> for mobile devices, always quick and comfortable'
        text="Access our mobile website, optimized to work smoothly on your smartphone. We have specially tailored it for your comfort, and to be responsive on even the most bloated devices"
        :main-image-source="homeDevicesImg"
        button-text="Read More"
        button-url="/products/mobile"
        button-color="blue-darker"
      />

      <HomeFeature
        class="v1 security"
        title='<span class="s-text-green-dark">Private</span>  and secure'
        text="<b>Seedr</b> protects your devices from the wild web.<br><br> When you paste a link, we transfer it onto our servers, without touching your device. Even if you close the site!"
        imageSource=""

        :main-image-source="homeSecurityImg"
        button-text="Read More"
        button-url="/products/secure"
        button-color="blue-darker"
      />
    </section>

    <section class="home-testimonials-bg">
      <HomeTestimonials2/>
    </section>

    <section class="home-section-2" id="home-section-2">
      <div class="home-section-2-bg ">
        <HomePlansContainer class="home-section-plans"/>
      </div>
    </section>

    <section class="home-blog-recent">
      <BlogRecent/>
    </section>

    <section class="home-subscribe">
      <HomeSubscribeForm/>
    </section>

    <section class="home-faq">
      <div class="home-plans-questions">
        <h2>{{ $t('home-page.questions-title') }}</h2>
        <div class="home-plans-cta">

          <s-button class="button-secondary" btn-link="https://www.seedr.cc/faq/">{{ $t('actions.faq') }}</s-button>
          <s-button class="button-secondary" btn-link="https://help.seedr.cc/hc/en-us/requests/new">{{ $t('actions.contact-us') }}</s-button>

        </div>
      </div>
    </section>
  </div>
</template>

<script>

import HomeHeroV1 from "#root/components/HomeHeroV1.vue";
import HomeFeature from '#root/components/HomeFeature.vue'
import HomeTestimonials2 from '#root/components/HomeTestimonials2.vue'
import HomePlansContainer from '#root/components/HomePlansContainer.vue'
import HomeSubscribeForm from '#root/components/HomeSubscribeForm.vue'
import SButton from "#root/components/SButton.vue";

import homeStreamImg from '#root/assets/images/home_feature_streaming_asset.svg';

import v2GrowingImg from '#root/assets/images/v2_growing_asset.svg?url'

import homeSecurityImg from '#root/assets/images/home_security-2_v1.svg?url'
import homeDevicesImg from '#root/assets/images/home_devices-3_v1.svg?url'
import SBanner from "#root/components/SBanner.vue";
import BlogRecent from "#root/components/BlogRecent.vue";



export default {
  components: {
    BlogRecent,
    SBanner,
    SButton,
    HomeHeroV1,
    HomeFeature,
    HomeTestimonials2,
    HomePlansContainer,
    HomeSubscribeForm
  },
  data() {
    return {
      bannerVisible: false,
      homeSecurityImg: homeSecurityImg,
      homeDevicesImg: homeDevicesImg,
      v2GrowingImg: v2GrowingImg,
      homeStreamImg: homeStreamImg
    }
  }
}
</script>
