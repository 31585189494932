<template>
  <div class="s-banner-comp  ">
    <a href="https://www.seedr.cc/premium?package=4">
      <img :src="blackFridayBannerImage" alt="Banner Image" class="s-pointer s-transition-03">
    </a>
  </div>
</template>

<script>
import blackFridayBannerImage from "/assets/images/banner_black-friday_2023.svg";

export default {
  props: {

  },
  data () {
    return {
      blackFridayBannerImage: blackFridayBannerImage,
    }
  }
}
</script>


